.fundraiser{
    margin-top: 20px;
    padding: 0;
    box-sizing: border-box;
}


@media screen and (max-width: 769px) {
    .services-cards{
        display:block;
        
    }

}
.fund-image{
width: 750px !important;
padding: 0px !important;
}

.banner{
    height: 60vh;
    width: 100vw;
}

.share{
    width:'300px' !important;
    padding: 20px;
    font-size: 14px;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    background: #111;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.fund-h1{
    font-size: 24px;
    font-weight: 550;
    color: #444;
}

.claim{
    width:'750px' !important;
  
    font-size: 14px;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    background: #111;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

}

#inline-checkbox-1{
    margin-top: 4px;
}

#inline-checkbox-2{
    margin-top: 4px;
}