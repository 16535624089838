.sidefund-container {
    padding: 30px;
    border-radius: 15px;
    max-width: 400px;
    margin: 0 auto;
  }
  
  .sidefund {
    background-color: #ffffff;
    padding: 25px;
    width: 360px;
    height: 370px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .contribute-btn {
    display: inline-block;
    margin-top: 20px;
  }
  
  .progress-container {
    height: 30px;
  }
  
  button {
    width: 100%;
    margin-top: 20px;
  }
  
  /* Media Queries for Mobile View */
  @media (max-width: 767px) {
    .sidefund-container {
      padding: 15px;
      width: 100%;
      margin: 0;
    }
  
    .sidefund {
      width: 100%;
      height: auto;
      padding: 15px;
    }
  
    .contribute-btn {
      width: 100%;
    }
  
    .my-3 {
      font-size: 20px;
    }
  
    /* Adjust font size for mobile */
    .sidefund h2 {
      font-size: 20px;
    }
  
    .sidefund .my-3 {
      font-size: 24px;
    }
  
    /* Resize Progress Bar */
    .progress-container {
      height: 20px;
    }
  
    .progress-bar {
      height: 100%;
    }
  }
  
  @media (max-width: 480px) {
    .sidefund {
      padding: 10px;
      font-size: 14px;
    }
  
    .sidefund h2 {
      font-size: 18px;
    }
  
    .sidefund .my-3 {
      font-size: 18px;
    }
  
    .progress-container {
      height: 15px;
    }
  
    .progress-bar {
      height: 100%;
    }
  }
  