

.avatar.sm {
    width: 2.25rem;
    height: 2.25rem;
    font-size: .818125rem;
}





/* .table-nowrap .table td,
.table-nowrap .table th {
    white-space: nowrap;
} */

/* .table>:not(caption)>*>* {
    padding: 0.75rem 1.25rem;
    border-bottom-width: 1px;
} */

/* table th {
    font-weight: 600;
    background-color: #eeecfd !important;
} */

.fa-arrow-up {
    color: #00CED1;
}

.fa-arrow-down {
    color: #FF00FF;
}
 
/* .list-donate td{
display: flex;
justify-content: center;
align-items: center;
} */