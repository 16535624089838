.footer-section{
    padding: 30px;
    background-color:#1E2127 !important;
    margin-top: 50px;
    color: white !important;
    font-family: sans-serif;
    display: flex;
    justify-content: space-around;
}

.contactus{
    font-size: 20px;
    font-weight: 600;

}
.content{
    /* font-size: 13px !important;
    font-weight: 400 !important;
    line-height: 10px !important; */
   
}
/* .content p{
    padding: 0 !important;
    margin: 0 !important;
} */
#address{
    font-size: 16px;
    font-weight: 600;
}

.white{
    padding-top: 50px;
    background-color: black !important;
}
#last{
    background-color: black;
    margin-left: 100px;
    padding-bottom: 40px;
    font-weight: bold;
}

.links{
    font-size: 16px;
    line-height: 25px;
}

 p{
    padding: 0px !important;
    margin: 0px !important;
   
}

.a{
    text-decoration: none;
    color: white;
}
.a:hover{
    transition: 0.3s ease-in-out !important;
    text-decoration: underline;
    text-decoration-color: #B80002;
    color: #B80002;
}












