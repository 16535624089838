
.containerr{
    width: 100%;
    padding: 50px;
    /* box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px; */
    }

    .services-cards{
        display: flex;
        justify-content: center;
        align-items: center;
        width:100%;
     
        
    }

    .btn-k{
        background-color: #B80002 !important;
        border-color: #B80002 !important;
        font-size: 14px !important;
        font-weight: 600 !important;
        margin: 20px;

    }

    .btn-k:hover,
.btn-k:focus {
  background-color: #F082AC !important;
  color: #FFFFFF !important;
  border-color: #F082AC !important;
}
    .card{
        margin: 40px 10px;
    }
    .card:hover{

        box-shadow: rgba(255, 254, 254, 0.08) 0px 4px 12px;
  
        transition: 0.3s ease-in-out;
    }
 
  

    .services{
        background: -webkit-linear-gradient(-45deg, rgba(31, 43, 112, 0.9) 0%, rgba(31, 43, 112, 0.9) 47%, rgba(148, 58, 158, 0.9) 100%);
        background: linear-gradient(135deg, rgba(1, 153, 255, 0.9) 0%, rgba(59, 64, 160, 0.92) 47%, rgb(184, 0, 2) 100%);
    }

    .card-title a{
        text-decoration: none;
        color: rgb(41, 36, 36);
        font-size: 18px;
    }
    .card-title a:hover{
        color:#1D9FFA;

       

    }

    .c1:hover{
       
        box-shadow:white 0px 5px 15px !important; 
      
        
       
    }

    @media screen and (max-width: 769px) {
        .services-cards{
            display:block;
            
        }
    
    }


    @media screen and (max-width: 360px){
       .card-text{
        font-size: 9px !important;
       }

       .card-title{
        font-size: 12px !important;
       }

       .card-image{
        height: 90px !important;
       }  
    
    .btn-se{
        margin-left: -2px !important;
        
    }

    .btn-seb{
        font: 11px !important;
    font-size: 9px !important;
    margin-bottom: -20px !important; 
    margin-top: -32px !important;
    }

    .rec-arrow{
        width: 10px !important;
    }

    .sc-bgqQcB{
        width: 10px !important;
    }


}
    
    
    
    