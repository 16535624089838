.blog-post-read{
    background-color: #fff;
    width: 750px;
    height: auto;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    
}

.blog-post{
    display: flex;
   
    justify-content: center;
    margin-top: 50px;
}

.post-text-list li{
   margin-left: 20px;
   padding-right: 25px;
    
} 
.submit{
  margin-left: 300px;
}