.header .navbar-brand {
  display: flex;
  align-items: center;
  gap: 17px;
  line-height: 20px;
}

.add_0 {
  padding: 20px 40px;
  display: flex;
  align-items: center;
  background-color: aliceblue;
  border-radius: 10px;
  gap: 35px;
  margin-top: 135px;
}
.di_90 {
  height: 300px;
  width: 300px;
}
.fw_90 {
  display: flex;
  justify-content: center;
}
input.name_0 {
  padding: 7px;
  border: 1px solid #141619;
  border-radius: 5px;
  outline-width: 0;
  font-size: 16px;
}
label {
  font-size: 18px;
  font-weight: 500;
}
.add-90 button {
  width: 100%;
  border: 1px solid #0d6efd;
  background: #0d6efd;
  padding: 4px;
  border-radius: 5px;
  color: white;
  font-size: 19px;
}
.left-side {
  background-color: #fcfcfc;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  height: 660px;
  top: 0px;
  position: sticky;
}
.si09 a {
  color: #89131a;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
}
.si09 a:hover {
  color: #ffb804;
}
.si09 ul {
  list-style: none;
  padding-top: 25px;
}
.a-ele {
  margin: 10px 0px;
  background: #8cb603;
  margin-right: 30px;
  color: white;
  border-radius: 40px;
  text-align: center;
  padding: 6px;
  font-size: 15px;
}
.ad-b button {
  border-radius: 3px;
  color: white;
  font-size: 18px;
  border: 1px solid;
  background-color: #8cb603;
  padding: 5px;
  font-weight: 500;
}
.table > thead {
  background-color: #8cb603 !important;
  color: white !important;
}
.btn-primary {
  background: #ffb902 !important;
  border-color: #ffb902 !important;
}
.vi_0 {
  border: 2px solid #dee2e6;
  outline-width: 0px;
  padding: 7px;
  border-radius: 5px;
  width: 100%;
}
.dask {
  border: 2px solid #dee2e6;
  padding: 21px;
  border-radius: 5px;
  background-color: #dee2e6;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.do-p {
  font-size: 25px;
  color: #141619;
  font-weight: 600;
  padding-bottom: 11px;
}
.no-p {
  border: 1px solid black;
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: center;
}
.do-n {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
}
.active-0 {
  background: #f08d15;
}
.a-ele:hover {
  background: #8f1612;
}
label {
  color: #842029;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid transparent;
  border-radius: 0.25rem;
  margin-bottom: 1.5rem;
  box-shadow: 0 2px 6px 0 rgb(218 218 253 / 65%),
    0 2px 6px 0 rgb(206 206 238 / 54%);
}
.me-2 {
  margin-right: 0.5rem !important;
}
.rounded-circle {
  border: 2px solid black;
  border-radius: 50%;
}
.ad_exp {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
.ad-dash {
  margin-left:10px;
}
