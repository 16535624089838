body{
    box-sizing: border-box;
}

.header-list{
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
    font-size: 11px;
color: #ffffff;
font-weight: 400;
text-transform: uppercase;
background-color:  #0199FF;
padding: 0;
margin: 0;
height: 40px;
  
}
.header-list li{
    list-style-type: none;
}

.header-list li  a{
    text-decoration: none;
    color: white;
}

.login a:hover {
 color:#B80002;
 font-weight: 600 !important;
 transition: 0.3s ease-in-out;

}

@media screen and (max-width: 412px) {
    .header-list{
        display: flex;
        align-items: center;
height: 50px;
      
    }

    .header-list li a{
        text-decoration: none;
        color: white;
    }

    
}

@media screen and (max-width: 755px) {

    .sri{
        font-size: 9px !important;
    }
}


@media screen and (max-width: 360px){
    .header-list {
    display: -webkit-box;}
}



