.about{
 
   
}
.container{
    
}
.text-header{
    color: #B80002;
    font-weight: 600px;
    text-align: center;
    font-size: 32px;
    line-height: 44px;
    line-height: -1px;
    font-family: sans-serif;
    height:70px
       
   
}

.container ul{
    margin: 50px;
    text-align: center;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;;
}

.container li{
    list-style-type: none;
}

#btn-k{
    padding: 30px;
   
}
#h2{
    font-size: 16px;
    font-weight: 400px;

}
