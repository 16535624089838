*{
    margin: 0px ;
    padding: 0px;

}

.formv{
   
    background-color:'';
}


.formvol{
width: 750px !important;
background-color: #F0F8FF;

}

.volunteer-f{
    border: solid 2px #F0F8FF;
background-color: #F0F8FF;
}

.form-check-input[type=radio] {
    border-radius: 50%;
    margin-top: 4px !important;
}