body{
    margin:0;
    padding: 0;
    box-sizing: border-box;
    

}

#h1{
    font-size: 20px;
   font-weight: 400;
   padding: 20px;
}

.text-header{
    color: #B80002;
    font-weight: 600px;
    text-align: center;
    font-size: 32px;
    line-height: 44px;
    line-height: -1px;
    font-family: sans-serif;
}




































