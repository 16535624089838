/* .blog-banner{
    background-image: url(./city-landscape-slider.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    height: 400px;
    width: 100%;  

} */

#banner-img{
    height: auto;
    max-width: 100%;
}

.flex-caption{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    padding: 0 30px;
    display: inline-block;
    max-width: 1170px;
    margin: auto;
    bottom: 50px;
}
.flex-caption h2
{
    /* background: rgb(218, 68, 83); */
    /* background: rgba(218, 68, 83, 0.7); */
    font-weight: 400;
    font-size: 24px;
    max-width: 640px;
    display: inline-block;
    padding: 12px 20px;
    margin-bottom: 0;
    /* color: #fff; */
}

 .flex-caption h{
 position: relative;
    top:100px;
    background: rgb(59, 59, 59);
    background: rgba(59, 59, 59, 0.8);
    display: block;
    color: #ddd;
    max-width: 600px;
    padding: 12px 20px; 
}
.textone{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-size: 22px;
    color: #DA4453;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    padding: 50px;
    margin-bottom: 10px;
    margin-top: 20px;
}
.blg-btn{
    background-color: transparent;
    color: #DA4453;
    padding: 15px 30px;
    border-color: #DA4453;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
    border-radius: 6px;
    text-decoration: none;
    border: solid 1px  #DA4453 ;
}
.blg-btn:hover{
background-color: #DA4453;
color: #fff !important;
}
.text-card{
    padding: 20px !important;
    color:#6B6b6b ;
    text-align: justify;
}


.card-date{
    padding: 0px 20px !important; 
  
}
.post-text{
    text-decoration: none;
    color: #DA4453;
}
.post-text:hover{
color: #6B6b6b !important;
text-decoration: none;
}

.post-btn{
    
 background-color: #DA4453;
 border-color: #DA4453 ;
 float: right;
 margin: 0px 20px 30px;
 font-size: 12px;
 padding: 11px 13px;
 font-weight: 500px;
 border-radius: 4px;
 text-decoration: none;
 color: #fff;

}

.post-btn:hover{
    text-decoration: none;
    color: #fff;
    background-color: #333;
    transition: 0.3s ease-in-out;
   
   
}

.sidetab{
    margin: 20px;
    width: 350px;
    height: auto;
   
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    background-color: #fff;
   

}

.text-sidetab{
    font-size: 16px;
    text-transform: uppercase;
    margin-bottom: 25px;
    font-weight: bold;
    margin-top: 0;
    color: #636467;
    text-transform: uppercase;
    font-family: sans-serif;
   

    /* color: #fff !important; */
    
}
.text-popular{
    font-size: 11px;
    width: 100%;
}

.category ul{
margin-top: 20px;

}
.category ul li{
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    text-align: left;
    margin-left: -30px;
    color: #6B6b6b;
    font-size: 15px;
    padding: 0px 0px;
    
    border-bottom:  0.5px solid #c2bdbd;
    
}

.category ul li:hover{
color: #DA4453;
}

.olderpost-btn{
    max-width:750px !important;
    height: 35px;
    background-color: #333;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0px;
    color: #fff !important;


}
.olderpost-btn .a-btn{
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 14px;

}

.olderpost-btn:hover{
    background-color: #DA4453;
}

