.blgfooter{
    width: 100%;
    background-color: #313233;
    display: flex;
    align-items: center;
    justify-content:center;


}


.post-text-f{
    text-decoration: none;
    color: #dadada;
}
.post-text-f:hover{
color:#fff !important;
text-decoration: none;
}

.category-f ul li:hover{
    color: #fff;
    }



.category-f ul{
    margin-top: 20px;
    
    }
    .category-f ul li{
        display: flex;
        align-items: center;
        justify-content: space-between;
        list-style: none;
        text-align: left;
        margin-left: -30px;
        color: #dadada;
        font-size: 15px;
        padding: 0px 0px;
        
        border-bottom:  0.5px solid #6B6b6b;
        
    }
    
    .footer-btm{
        width: 100%;
        height: auto;
        background-color: #1f1f1f;
    }
    
    .icons{
        text-align: center;
    }
    