.gallery{
    margin: 0px;
    padding: 0px;
    box-sizing:border-box ;
}
*{
    margin: 0px;
    padding: 0px;
    box-sizing:border-box ;
}  

.g-h1{
    font-size: 30px;
    text-transform: uppercase;
    color: #B80002 !important;
    margin-top: 60px;
   
}

.banner-g{
    width: 100%;
    height: 100px;
    display: flex;
    justify-content:center;
    align-items: center;
    /* background-color:#0198FF; */
}

.image{
   
        margin:3px 11px;
        width:350px;
        height: 266px;
      
       
        box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

}
/* .image img:hover{
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
} */



.image img{
    margin: 0px;
    padding: 0px;
    width:350px;
    height: 266px;
   
}
.gallery-list{
    display: flex;
    justify-content: center;
    align-items: center;
    
  
    padding: 0;
}

.gallery-list li{
    list-style-type: none;
}

@media screen and (max-width: 769px) {

    .gallery-list{
        display: block;
        justify-content: center;
        align-items: center;
        
      
        padding: 0;
    }
    
  
}