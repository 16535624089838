.news{
    margin-top: 70px;
}
.news-list{
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 769px) {

    .news-list{
        display: block;
       
        align-items: center;
    }

    .news{
        margin-top: 70px;
    }
    
  
}
