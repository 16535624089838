.id-card-wrapper {
    width:100%;
    background-color: #8cb603;
    /* display: flex; */
  }
  .id-card {
    flex-basis: 100%;
    max-width: 30em;
    border: 2px solid #8cb603;
    margin: auto;
    color: #fff;
  }
  .desc p{
    font-size: 13px;
    color: white;
    margin-bottom: 0px;
  }
  .profile-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .profile-row .dp {
    flex-basis: 33.3%;
    position: relative;
    margin: 20px 0px 0px;
    align-self: center;

  }
  .profile-row .desc {
    flex-basis: 66.6%;
  }
  
  .profile-row .dp img {
    border-radius: 50%;
    display: block;
    width: 100px;
    height: 100px;
  }
  .profile-row .desc {
    padding: 1em;
  }
  
  /* .profile-row .dp .dp-arc-inner {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 6px solid transparent;
    border-top-color: #0AE0DF;
    border-radius: 50%;
    top: -6px;
    left: -6px;
  
    animation-duration: 2s;
    animation-name: rotate-clock;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  @keyframes rotate-clock {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .profile-row .dp .dp-arc-outer {
    position: absolute;
    width: calc(100% + 12px);
    height: calc(100% + 12px);
    border: 6px solid transparent;
    border-bottom-color: #0AE0DF;
    border-radius: 50%;
    top: -12px;
    left: -12px;
  
    animation-duration: 2s;
    animation-name: rotate-anticlock;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  @keyframes rotate-anticlock {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(-360deg);
    }
  } */
  
  .profile-row .desc {
    /* font-family: 'Orbitron', sans-serif; */
    text-align: center;
  }
  .profile-row .desc h1 {
    margin: 0px;
    font-size: 25px;
    font-weight: 500;
    color: black;
  }
  .desc_0{
    text-align: center;
  }
  .desc_0 p{
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 0px;
  }
  