.hs-image{

    width: 1030px;
  
 
  
    height: 496px;
   
}
.container{
    border: solid 2px white;
   
  
}