.card:hover{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.activefund{
    display: flex;
    justify-content:center;
    align-items: center;
    margin-top: 10px;
   
}
@media screen and (max-width: 769px) {
    .activefund{
        display: block;
       
        padding:0px;
    }

}