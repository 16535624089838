/* body {
    margin: 3em;
  } */
  .cit {
    width: 60%;
  
    margin: 0 auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  .c {
    padding: 1.5em 0.5em 0.5em;

   
    /* border-radius: 2em; */
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  }
  .card-title {
    font-weight: bold;
    font-size: 1em;
  }
  .btn-primary {
    border-radius: 2em;
    padding: 0.5em 1.5em;
  }
  .c-image{
    /* box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px; */
  }

  .card-text{
    font-weight: 500;
    font-size: 1em;
  }

  @media screen and (max-width: 1080px) {

    .card {
      padding:5px;
      width: 13rem !important;
      background-color: #fff !important;
     

  }
  .image-card{

    margin-top: -4px !important;
    padding: 10px;
    height: 141px !important;
    width: 203px !important;
    margin-top: -10px;
    margin-left: -4px;
}
  .card-title{
    font-size:14px ;
  }

  .card-text{
    font-size: 13px;
  }


  }


  @media screen and (max-width: 1020px) {

  .founder-image{
   margin: 17px 30px 20px 43px !important;
    padding: 0px
}
.founder-text
{
  padding: 0px;
  margin: 11px 20px 30px 52px !important;
} 


.founder-image{
  margin: 20px 30px 20px 52px !important ;
  padding: 0px;
  
}
.founder-text{
  margin:13px 20px 70px 32px;
}

  }

  @media screen and (max-width: 1320px) {

    .card {
      padding:5px;
      /* width: 16rem !important; */
      background-color: #fff !important;
     

  }
  .image-card{

    margin-top: -4px !important;
    padding: 10px;
    height: 150px !important;
    width: 253px !important;
    margin-top: -10px;
    margin-left: -4px;
}
  .card-title{
    font-size:14px ;
  }

  .card-text{
    font-size: 13px;
  }





  }

  @media screen and (max-width: 1219px) {
    .card {
      padding:5px;
      width: 15rem !important;
      background-color: #fff !important;
     

  }
    .image-card{

      margin-top: -4px !important;
      padding: 10px;
      height: 150px !important;
      width: 236px !important;
      margin-top: -10px;
      margin-left: -4px;
  }
  }