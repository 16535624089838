.carousel-control-prev-icon{
    margin-right: 150px;
    border:solid 2px white;
    border-radius: 50%;
    color: white !important;
}
.carousel-control-next-icon{
    margin-left: 150px;
    border:solid 2px white;
    border-radius: 50%;
    color: white !important;
   
}

/* .slider{
    background-image: linear-gradient(to right, #434343 0%, black 100%) !important;} */

li{
    list-style-type: none;
}
.article {
   
    position: relative !important;
    overflow: hidden !important;
}

/* .picture {
    object-fit: cover;
    width: 100%;
    height: 100%;
} */

.carousel-caption  {
   
  
    text-align: center !important;
    position: absolute !important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: fit-content;
    margin: auto;
}

.img-home{
    height: 550px !important;
}
.slider-text{
    margin-top: 276px;
}
.slider-text ul{
 
    position: relative;
    
    top: -450px;

}
.slider-text li{
    list-style-type: none;
    
    
}
.slider-text ul{
    text-align: left;
    margin-left: 400px;
   
    


}

.slider-text ul a{
    font-weight: 600;
    text-decoration: none;
    background-color: white;
    color:red;
}

.slider-text ul a:hover{
background-color: red;
border-color: red !important;
color: white !important;
transition: 0.3s ease-in-out;
}

@media screen and (max-width: 755px) {

    .slider{
        height: 422px !important;
    }

    .img-home{
        height: 422px !important;
    }


}

@media screen and (max-width: 360px){
    .header-list {
    display: -webkit-box;}

    .slider{
        height: 300px !important;
    }

    .img-home{
        height: 300px !important;
    }
    .container{
        width: 400px !important; 
        padding: 0px !important;
        font-size: 14px !important;
    }

    
}



