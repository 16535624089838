.contact-form{
    border:solid 2px white;
    padding:50px ;
    border-radius: 20px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    
}
.contact-info{
    display: flex;
    align-items: center;
    justify-content: space-around;
}


.box{
    width:290px;
    height:150px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
   
    font-family: sans-serif;
 
}
.box-h{
    color:#DA4453;
    display: flex;
    justify-content: center;
    align-items: center;
  
    font-size: 18px ;
    font-weight: 600;
   line-height: 20px;
   padding-top: 20px !important;
}

