*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;
}
.logo-text{
font-size: 1rem;
font-weight: 600;
color: #90191F;
font-family: sans-serif;
margin-left: 2px;
text-transform: uppercase;
line-height: 10px;
}
.logo-img-s{
height: 50px ;
width: 50px;
}


@media screen and (max-width: 386px) {
.logo-img-s{
  height: 35px ;
  width: 35px;
  }
  .logo-text{
    font-size: 0.8rem;
  

    
 
  }


  @media screen and (max-width: 310px) {

    .logo-img-s{
      height: 25px ;
      width: 25px;
      }
      .logo-text{
        font-size: 0.7rem;
      
    
        
     
      }
        
       
       
      
  
  }
    
   
   
  

}

/* CSS */
.button-75 {
margin: auto;
/* background-image: linear-gradient(135deg, #f34079 40%, #fc894d); */
/* background-color: #AC1224; */
background-color: #842029;
border: 0;
border-radius: 10px;

color: #fff;
cursor: pointer;


font-family: "Codec cold",sans-serif;
font-size: 16px;
font-weight: 700;
height:45px;

letter-spacing: .4px;
line-height: 1;
max-width: 100%;
padding-left: 20px;
padding-right: 20px;
padding-top: 3px;
text-decoration: none;
text-transform: uppercase;


touch-action: manipulation;
}

.button-75:active {
outline: 0;
}

.button-75:hover {
outline: 0;
}

.button-75 span {
transition: all 200ms;
}

.button-75:hover span {
transform: scale(.9);
opacity: .75;
}

@media screen and (max-width: 991px) {
.button-75 {
  font-size: 15px;
  height: 50px;
}

.button-75 span {
  line-height: 50px;
}
}


nav {
position:sticky;
z-index: 99;
top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  padding: 10px 45px;
  /* box-shadow: 0 5px 15px rgba(0, 0, 0, 0.6); */
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    
}
a{
  text-decoration: none !important;
}
#navbar {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#navbar .list {
  list-style: none;
  padding: 0px 20px;
  position: relative;
}

#navbar .list a {
  
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: 600;
  color: black;
  transition: 0.3s ease-in-out;
}
#navbar .list a:hover,
#navbar .list a.active {
  color: #90191F;
}
#navbar .list a:hover::after,
#navbar .list a.active::after {
  content: "";
  width: 30%;
  height: 2px;
  background: #90191F;
  position: absolute;
  bottom: -4px;
  left: 20px;
}


#donate-btn a {
  color: white;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;

  transition: all 0.3s ease-in-out;
}

#mobile {
  display: none;
}

#mobile i {
  align-items: center;
  color: black;
}








.button-1 {

  background:#90191F;
border-radius: 8px;
border-style: none;
box-sizing: border-box;
color: #FFFFFF;
cursor: pointer;
display: inline-block;
font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
font-size: 13px;
font-weight: 600;
max-height: 40px;
line-height: 20px;
list-style: none;
margin: 0;
outline: none;
padding: 6px 14px;
position: relative;
text-align: center;
text-decoration: none;
transition: color 100ms;
vertical-align: baseline;
user-select: none;
-webkit-user-select: none;
touch-action: manipulation;
}

.button-1:hover,
.button-1:focus {
background-color: #6e1f23;
color: #FFFFFF;
}
.img-text-logo{
max-width: 15rem;
min-width: 10rem;
}
@media screen and (max-width: 1323px) and (min-width:1165px) {

#navbar li {
padding:0px 10px;
}
#navbar li a {
  font-size: 13px;
  
}


}
@media screen and (max-width: 1164px) and (min-width:960px) {

#navbar li {
padding:0px 5px;
}
#navbar li a {
  font-size: 11px;
  font-weight: 500;
  
}
.btn.btn-outline-danger{
font-size: 11px;
}
}



@media screen and (max-width: 360px) {  

.fa-bars:before{
  /* margin-left: -28px; */
}
.btn-32{
  font-size: 11px !important;
  margin-left: 30px !important;

}
.logo-v{
  width: 35px !important ;
  height: 35px !important;
  margin-left: 10px !important;

}
.logo-1{
  max-height: 25px !important;
  width: 70px;
 
}

.logo-2{
  max-height: 19px !important;
  width: 120px;
  margin-top: -20px !important;
  margin-left: -3px;
}
/* .fa-times:before{
  margin-left: -25px !important;
} */

.logo-c{
  display: contents;
  margin-left: 5px;
}
}




@media screen and (max-width: 992px) {   
.h {
  font-size: 0.7rem;}
.logo-v{
  width: 30px ;
  height: 30px;
}
.button-75{
  height: 30px;
  font-size: 11px;
  margin-left: 10px;
}

#navbar li{
  padding: 4px;
}

.nav
{
  padding-inline:10px ;
}

#navbar li a{
  font-size: 0.7rem;
}

.img-text-logo {
  max-width: 12rem;
  min-width: 10rem;
}

} 


@media screen and (max-width: 862px) {   

.logo-v{
  width: 30px ;
  height: 30px;
}
.button-75{
  height: 30px;
  font-size: 11px;
  margin-left: 10px;
}

#navbar li{
  padding: 4px;
}

.nav
{
  padding-inline:10px ;
}

#navbar li a{
  font-size: 0.7rem;
}

.img-text-logo {
  max-width: 10rem;
  min-width: 10rem;
}

} 


@media screen and (max-width: 829px) {  
nav{
  padding: 10px 20px ;
}

}

@media screen and (max-width: 779px) {  
#navbar {

padding: 0px;
}

}





@media screen and (max-width: 769px) { 
#navbar {
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: flex-start;
position: absolute;
top: 48px;
width: 300px;
height: 100vh;
left: -300px;
background: white;
box-shadow: 0 40px 60px rgba(0, 0, 0, 0.6);
padding: 40px 0 0 10px;
transition: 0.3s ease-in-out;
}
.vlogo{
display:inline;
}


#donate-btn {
display: none;
}

#navbar.active {
left: 0px;
}

#mobile {
display: block;
}
#navbar li {
margin-bottom: 25px;
}

#mobile i {
font-size: 24px;
cursor: pointer;
}

.img-text-logo{
max-width: 15rem;
min-width: 10rem;
}



}

