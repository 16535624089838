.headbanner{
    width: 100%;
    height: 100px;
    background: #0199FF;
    /* linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.3)), rgba(0, 0, 0, 0.3) url(../s.jpg) no-repeat center; */
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.text-hs{
    font-size: 28px;
    text-align: center;
    color: white;
}
.hs-image img{

    width: 1030px;
    margin-top: 30px !important;
    
    height: 496px;
   
}

.container{
    
    
    border: solid 2px white;
    padding: 50px !important;
    background-color: white;
    
}