

.contact-form-text{
    font-size: 20px;
    font-weight: 500;
    }
    
    .form-label{
        font-size:18px !important ;
    }
    
    .form-c{
        display: block;
        width: 100%;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        outline: none;
        height: 50px;
        
    
    }
    
    .btn-submit{
        padding: 10px 20px;
        font-size: 18px;
        font-weight: 500;
        color:white ;
        background-color: #5A8BAA;
        text-transform: uppercase;
      
    }